<template>
  <Navigation>
    <div id="restaurant" class="main-container">
      <v-row>
        <v-col class="px-0" cols="12" md="6">
          <!-- <h2>{{ $t("application.pages.meltemi.text.title") }}</h2> -->
          <h2>
            {{ $t("application.pages.meltemi.text.title1") }}
            <span>Meltemi</span>
            {{ $t("application.pages.meltemi.text.title2") }}
          </h2>
          <p
            class="text-content"
            v-html="$t('application.pages.meltemi.text.content')"></p>
        </v-col>
        <v-col class="text-center px-0" cols="12" md="6">
          <v-row class="pa-0">
            <v-col class="pt-0"
              ><h3>{{ $t("application.pages.meltemi.contact.owner") }}</h3>
              <v-avatar class="avatar"
                ><v-img src="/assets/images/ntina.jpg" eager></v-img
              ></v-avatar>
              <div class="pa-0">
                <span>{{
                  $t("application.pages.meltemi.contact.owner.name")
                }}</span>
              </div></v-col
            >
          </v-row>
          <v-row class="pa-0">
            <v-col class="pt-0"
              ><h3>{{ $t("application.pages.meltemi.contact.telephone") }}</h3>
              <a href="tel:+302892095367"
                ><span>+30 28920 95367</span></a
              ></v-col
            >
          </v-row>
          <v-row class="pa-0">
            <v-col class="pt-0"
              ><h3>{{ $t("application.pages.meltemi.contact.email") }}</h3>
              <a href="mailto:meltemi@lendas.gr"
                ><span>meltemi@lendas.gr</span></a
              ></v-col
            >
          </v-row>
          <v-row class="px-0">
            <v-col class="pt-0"
              ><h3>{{ $t("application.pages.meltemi.contact.address") }}</h3>
              <span
                >{{ $t("application.pages.meltemi.contact.address.text") }}
              </span>
            </v-col>
          </v-row>
          <v-row class="px-0">
            <v-col class="pt-0"
              ><h3>
                {{ $t("application.pages.meltemi.contact.business.hours") }}
              </h3>
              <span
                >{{
                  $t("application.pages.meltemi.contact.business.hours.text")
                }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" align="baseline" class="px-0">
        <v-col cols="auto" class="text-center">
          <h1>
            {{ $t("application.pages.menu.title") }}
          </h1>
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
        align="baseline"
        class="px-0"
        style="min-height: 20rem">
        <v-col
          v-observe-visibility="{
            callback: visibilityChanged,
            once: true,
          }"
          cols="6"
          lg="3"
          v-for="(item, index) in selectedItems"
          :key="index"
          :style="`--index:${index + 1};`">
          <transition name="open-item-col">
            <OpenItemCircleMini
              v-if="show"
              class="rounded"
              @click="
                $router.push(
                  `/menu/${item.category}/${item.title[language.lcid]}`
                )
              "
              :item="item"
              :selectedLocale="language"></OpenItemCircleMini>
          </transition>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto"
          ><router-link to="/menu" class="a-link">
            <h1>{{ $t("application.pages.menu.more") }}</h1></router-link
          ></v-col
        >
      </v-row>

      <v-row class="px-0 pt-10" justify="center">
        <v-col cols="auto" class="text-center">
          <h2 class="customer-says">
            {{ $t("application.pages.meltemi.whatcustomersays") }}
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center" align="baseline" class="ma-0">
        <v-col cols="auto" v-for="(social, i) in getSocialNetworks" :key="i">
          <v-btn x-large icon :href="social.url" target="blank"
            ><v-icon x-large class="social-icon">{{
              social.contactType.icon
            }}</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "../components/navigation/Navigation.vue";
import OpenItemCircleMini from "@/components/openItem/OpenItemCircleMini";
import { mapGetters } from "vuex";

export default {
  components: {
    Navigation,
    OpenItemCircleMini,
  },
  mounted() {
    this.$store.commit("carousel/setTitle", "application.pages.meltemi.title");
    this.$store.commit(
      "carousel/setSubtitle",
      "application.pages.meltemi.subtitle"
    );
    this.$store.commit("carousel/setImage", "/assets/carousel/carousel_5.jpg");
    this.$store.commit(
      "carousel/setHeaderImage",
      "/assets/carousel/carousel_6.jpg"
    );
    this.$store.commit(
      "carousel/setHeaderTitle",
      "application.pages.meltemi.header"
    );
  },
  computed: {
    ...mapGetters("business", ["infos", "images", "items"]),
    ...mapGetters("loader", ["loading"]),
    ...mapGetters("system/", ["language"]),
    socialNetworkTypes() {
      return ["ADDRESS", "TRIPADVISOR"];
    },
    selectedItems() {
      const selectedUUIDs = [8, 6, 20, 15];

      return this.items.filter((i) => selectedUUIDs.includes(i.uuid));
    },
    getSocialNetworks() {
      if (this.infos.businessContactInfo) {
        return this.infos.businessContactInfo
          .filter((i) => this.socialNetworkTypes.includes(i.contactType.name))
          .map((i) => {
            if (i.contactType.name === "ADDRESS") {
              i.contactType.icon = "fab fa-google";
              return i;
            }
            return i;
          });
      }
      return [];
    },
  },
  data() {
    return { show: false };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.show = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  h1 {
    font-family: "Allura", sans-serif;
    font-weight: 200;
    font-size: 3rem;
  }
  h2 {
    font-family: "Raleway", sans-serif;
    font-size: 2.5rem;
    text-align: center;
    span {
      color: var(--v-primary-base) !important;
      font-family: "Allura", sans-serif;
      font-size: 3.5rem;
      font-weight: bold;
    }
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  span,
  p {
    font-family: roboto;
    font-weight: 300;
    font-size: 1.5rem;
  }
  a {
    text-decoration: none;
    &:hover span {
      color: var(--v-primary-base) !important;
    }
  }
}
.a-link {
  border: 1px solid;
  height: 6rem;
  line-height: 6rem;
  background-color: #fafafa70;
  border-radius: 6px;
  display: block;
  transition: border-color, background-color, 1s;

  h1 {
    transition: color, 1s;
    padding: 0 2rem;
  }
  &:hover {
    background-color: white;
  }
  &:hover,
  &:hover h1 {
    color: var(--v-primary-base) !important;
  }
  .social-icon {
    &:hover {
      color: var(--v-primary-base) !important;
    }
  }
}

.open-item-col-enter-active,
.open-item-col-leave-active {
  transition: transform 1s;
  transform: translateX(0);
  transition-delay: calc(0.1s * var(--index));
}
.open-item-col-enter,
.open-item-col-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}

.main-container {
  padding: 1em;
}
.avatar {
  box-shadow: -1px -1px 2px hsla(0, 0%, 100%, 0.3),
    1px 1px 2px rgba(0, 0, 0, 0.3), inset -1px -1px 2px rgba(0, 0, 0, 0.3),
    inset 1px 1px 2px hsl(0deg 0% 100%);
}

.customer-says {
  position: relative;
  font-size: 2.5rem;
  line-height: 2.5rem;
  border-bottom: 4px solid var(--v-primary-base);
  display: inline;
}

* {
  color: #424242 !important;
}
</style>
